import React, { Suspense, lazy } from 'react';
import { Redirect, Switch, Route } from "react-router-dom";
import './App.css';
import Loader from './components/Loader/Loader';
const NonUser = lazy(() => import('./views/NonUser/NonUser'))
const User = lazy(() => import('./views/User/User'));

class App extends React.Component {
  // TODO might switch user from state to server
  state = {
    user: false
  }
  componentDidMount() {
  }
  render() {
      console.log(process.env.REACT_APP_BASE_URL, 'base url')
      return (
      // this.state.user ? <User /> : <NonUser />
    <Suspense fallback={<Loader/>}>
        <Switch>
          <Route path="/dash" component={User} />
          <Route path="/" component={NonUser} />
        </Switch>
      </Suspense>
    );
  }

}

export default App;
