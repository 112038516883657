import React from 'react'
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const styles = {
    spinner: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}

const useStyles = makeStyles(styles);

export default function Loader() {
    const classes = useStyles();
    return (
        <div className={classes.spinner}>
            <CircularProgress/>
        </div>
    )
}